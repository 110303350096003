import styled from 'styled-components';

export const CheckButton = styled.button<{ checked: boolean }>`
  flex: 1;
`;

export const IconContainer = styled.div`
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CheckLabel = styled.div<{ checked: boolean }>`
  background-color: ${(props) => (props.checked ? props.theme.textDark : props.theme.background)};
  height: 56px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  flex-direction: column;
`;

export const CheckText = styled.p<{ checked: boolean }>`
  font-size: 14px;
  color: ${(props) => (props.checked ? props.theme.background : props.theme.textDark)};
`;

export const CheckSubText = styled.p<{ checked: boolean }>`
  font-size: 12px;
  color: ${(props) => (props.checked ? props.theme.background : props.theme.backgroundMid)};
`;
