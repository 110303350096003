import { largeTabletMax, mobileMax, mobileXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import { Container as ButtonContainer } from '@propertypal/web-ui/src/buttons/Button.style';
import { Container as DropButton } from '@propertypal/web-ui/src/buttons/DropButton.style';
import { Container as ListButtons } from '@propertypal/web-ui/src/buttons/ListButtons.style';
import { Label } from '@propertypal/web-ui/src/inputs/Checkbox.style';
import { CheckButton, CheckLabel } from '@propertypal/web-ui/src/inputs/CheckItem.style';
import MultiSelect from '@propertypal/web-ui/src/inputs/MultiSelect';
import {
  InputBox as MultiSelectBox,
  Placeholder as MultiSelectPlaceholder,
} from '@propertypal/web-ui/src/inputs/MultiSelect.style';
import SelectInput from '@propertypal/web-ui/src/inputs/SelectInput';
import { OptionItem } from '@propertypal/web-ui/src/inputs/SelectInput.style';
import Toggle from '@propertypal/web-ui/src/inputs/Toggle';
import { Container as ToggleSwitchContainer } from '@propertypal/web-ui/src/inputs/ToggleSwitch.style';
import { Column } from '@propertypal/web-ui/src/layout';
import { MAX_WIDTH } from '@propertypal/web-ui/src/layout/PageWrapper.style';
import { InputLabel } from '@propertypal/web-ui/src/typography';
import styled from 'styled-components';

export const filterBreakpoint = 'max-width: 940px';

export const checkItemStyles = {
  minWidth: 230,
  maxWidth: 230,
  marginRight: 10,
  marginTop: 15,
};

export const selectStyle = {
  boxShadow: 'none',
  borderRadius: 4,
};

export const SearchBarForm = styled.div`
  width: 100%;
  padding: 10px 20px;
  flex-direction: row;
  display: flex;
  justify-content: center;
  background: ${(props) => props.theme.primary};

  ${ButtonContainer} {
    width: 172px;
  }

  @media (${filterBreakpoint}) {
    padding: 10px;

    ${ButtonContainer} {
      width: 60px;

      svg {
        margin-right: 0 !important;
      }

      .buttonText {
        display: none;
      }
    }
  }
`;

export const InputContainer = styled.div`
  position: relative;
  background: ${(props) => props.theme.background};
  flex-direction: row;
  display: flex;
  height: 60px;
  min-width: 0;
  align-items: center;
  flex: 1;
  margin-right: 15px;
  border-radius: 6px;
  max-width: ${MAX_WIDTH - 250}px;

  @media (${filterBreakpoint}) {
    margin-right: 5px;
  }
`;

export const FiltersButton = styled.button<{ active: boolean }>`
  position: relative;
  display: none;
  height: 100%;
  align-items: center;
  padding: 0 20px 0 15px;
  font-size: 14px;
  font-weight: 600;
  color: ${(props) => (props.active ? props.theme.primary : props.theme.textDark)};

  @media (${filterBreakpoint}) {
    display: flex;
  }

  @media (${mobileMax}) {
    padding: 0 15px;
  }

  &:before {
    content: ' ';
    background-color: ${(props) => props.theme.backgroundLight};
    position: absolute;
    width: 1px;
    height: 34px;
    left: 0;
  }
`;

export const FilterContainer = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.backgroundLighter};
`;

export const FiltersContent = styled.div`
  height: 94px;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${MAX_WIDTH - 20}px;
  margin: 0 auto;
  padding: 20px;

  ${DropButton} {
    margin-right: 20px;
  }

  @media (${largeTabletMax}) {
    ${DropButton} {
      margin-right: 5px;
    }
  }

  @media (${filterBreakpoint}) {
    padding: 10px;
    height: 74px;

    ${DropButton} {
      display: none;
    }
  }
`;

export const FiltersPane = styled.div<{ $overflow?: boolean }>`
  position: absolute;
  top: 94px;
  left: 0;
  background-color: ${(props) => props.theme.backgroundLighter};
  width: 100%;
  transition: 0.4s all ease;
  padding: 20px;
  z-index: 5;

  ${(props) =>
    props.$overflow &&
    `
    max-height: calc(100vh - 230px);
    overflow: auto;
  `}

  @media (${filterBreakpoint}) {
    display: none;
  }
`;

export const FilterPaneContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: ${MAX_WIDTH}px;
  padding: 0 30px;
`;

export const SearchModal = styled.div<{ active: boolean }>`
  position: absolute;
  background-color: ${(props) => props.theme.backgroundLightest};
  width: 100%;
  height: 0;
  opacity: 0;
  top: 137px;
  z-index: 30;
  transition: 300ms all;
  visibility: hidden;

  ${(props) =>
    props.active &&
    `
    height: calc(100vh - 137px);
    opacity: 1;
    visibility: visible;
  `}
`;

export const ScrollDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  padding: 0 30px 202px;

  @media (${mobileXLargeMax}) {
    padding: 0 15px 202px;
  }
`;

export const CloseIcon = styled.button`
  position: absolute;
  top: 5px;
  right: 15px;
  padding: 10px;
`;

export const ModalFooter = styled.div`
  display: flex;
  width: 100%;
  background-color: ${(props) => props.theme.background};
  border-top: 1px solid ${(props) => props.theme.keyline};
  flex-direction: row;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  justify-content: center;
  z-index: 1;

  @media (max-height: 500px) {
    bottom: 0;
  }
`;

export const InputRow = styled.div<{ mt?: string }>`
  display: flex;
  flex-direction: row;
  margin-top: ${({ mt }) => mt || '40px'};

  @media (${filterBreakpoint}) {
    flex-direction: column;
  }
`;

export const InputColumn = styled.div<{ direction?: string }>`
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (${largeTabletMax}) {
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
      margin-right: 10px;
    }
  }

  @media (${filterBreakpoint}) {
    margin-left: 0;
    flex-direction: ${({ direction }) => direction || 'row'};

    &:first-child {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }
`;

export const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  grid-column-gap: 15px;
  grid-row-gap: 20px;
`;

export const ToggleView = styled.div`
  @media (${largeTabletMax}) {
    ${ListButtons} p {
      font-size: 14px;
    }
  }

  @media (${filterBreakpoint}) {
    flex: 1;

    ${ListButtons} p {
      font-size: 16px;
    }
  }

  @media (${mobileMax}) {
    ${ListButtons} a {
      padding: 0 10px;
    }
  }
`;

// Currency toggle on desktop needs space to the right due to Price Range
// But on mobile, needs space to the left due to Select a Category
export const CurrencyToggle = styled(Toggle)`
  max-width: 120px;
  margin-right: 20px;

  @media (${filterBreakpoint}) {
    margin-left: 20px;
    margin-right: 0;
  }
`;

export const SmartMultiSelect = styled(MultiSelect)`
  width: 100%;
  max-width: 260px;

  @media (${filterBreakpoint}) {
    max-width: none;
  }
`;

export const StyledSelect = styled(SelectInput)`
  width: 100%;
  max-width: 260px;
  box-shadow: none;

  ${DropButton} {
    span {
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }

  ${OptionItem} {
    font-weight: 600;
    font-size: 14px;
  }

  @media (${filterBreakpoint}) {
    max-width: none;
    flex-basis: 50%;

    ${DropButton} {
      box-shadow: none !important;
      border-radius: 4px;

      span {
        padding-left: 0;
      }
    }
  }
`;

export const SmartColumn = styled(Column)<{ gap?: string }>`
  flex: 1;

  ${Label} {
    font-size: 14px;
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${MultiSelectPlaceholder} {
    font-size: 14px;
    font-weight: 600;
  }

  ${OptionItem} {
    font-weight: 600;
    font-size: 14px;
  }

  @media (${filterBreakpoint}) {
    align-items: center;
    flex-direction: row;
    flex: 0 0 calc(50% - 5px);

    &:not(:last-child) {
      margin-right: 0;
      margin-bottom: 20px;
    }

    ${MultiSelectBox} {
      box-shadow: none;
      border-radius: 4px;
    }

    ${ToggleSwitchContainer} {
      margin-left: 20px;
    }

    ${InputLabel} {
      min-width: 175px;
      margin-bottom: 0;
    }
  }
`;

export const SmartFiltersWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  width: 100%;

  @media (max-width: 1140px) {
    flex-direction: column;
  }
`;

export const EPCWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  margin-top: 30px;

  @media (${filterBreakpoint}) {
    width: 100%;
    flex-direction: row;
    margin-right: 0;

    & > div {
      flex: 0 0 calc(50% - 5px);
    }

    & > div:nth-child(2) {
      margin-left: 10px;
    }
  }

  ${Label} {
    font-size: 14px;
  }
`;

export const PreferenceColumnContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;

  @media (${filterBreakpoint}) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const PreferenceColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:first-child {
    margin-right: 20px;
  }

  @media (${filterBreakpoint}) {
    width: 100%;
    &:first-child {
      margin-right: 0px;
    }
  }
`;

export const PreferencePill = styled.span`
  padding: 2px 7px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.lightGrey};
  text-transform: uppercase;
  margin-bottom: 8px;
  width: fit-content;
  color: ${({ theme }) => theme.backgroundDark};
  font-size: 14px;
  font-weight: 600;
`;

export const PreferenceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  ${CheckButton} {
    width: 190px;
    flex: inherit;
  }

  ${CheckLabel} {
    font-weight: 600;
    padding: 0 8px;
  }

  @media (${filterBreakpoint}) {
    ${CheckButton} {
      width: calc(50% - 5px);
    }
  }
`;

export const SaveSearchWrapper = styled.div`
  display: none;

  @media (${filterBreakpoint}) {
    flex: 1;
    margin-left: 10px;
    display: block;
  }

  @media (${mobileMax}) {
    margin-left: 5px;
  }
`;
